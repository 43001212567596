<template>
  <v-dialog v-model="model" max-width="1200px">
    <TemplateAlert
      :showAlert
      :text="alertText"
      type="warning"
    >
    </TemplateAlert>
    <v-card class="pa-5" v-if="model">
      <template #title>
        <h2 class="ma-0" v-html="dialogTitle"></h2>
      </template>
      <template #append>
        <v-btn @click="model = false" icon="mdi-close" />
      </template>
      <v-card-text>
        <QuestionTypeSelector
          v-if="!editingQuestion || selectQuestionType"
          :root
          :enableRootLevelRepeater
          @questionTypeSelected="questionTypeSelected"
        />
        <template v-else>
          <template v-if="questionTypeCard">
            <div class="d-flex align-center">
              <v-btn
                color="lightPurple"
                density="compact"
                :icon="questionTypeCard.icon"
              />
              <h2 class="ml-2 mt-0">{{ questionTypeCard.title }}</h2>
            </div>
            <v-btn
              @click="selectQuestionType = true"
              color="lightPurple"
              variant="text"
            >
              <v-icon start> mdi-arrow-left </v-icon>
              Skift type
            </v-btn>
          </template>
          <div class="text-right text-caption">
            <!-- validation rules - displayed inline, edited in a popup-dialog  -->
            <ValidationRules :editingQuestion />

            <!-- key - edited in a popup-dialog  -->
            <DimsDialog title="Backend nøgle">
              <template #activator="{ props }">
                <span class="text-right text-caption">
                  Backend nøgle: <code>{{ editingQuestion.key || "Ingen" }}</code>
                  <v-btn icon="mdi-key-link" v-bind="props"></v-btn>
                </span>
              </template>
              <p>
                En unik identifier som kobler spørgsmålet til
                forretningslogik. NB: Ændring af dette kan medføre fejl i
                backend.
              </p>
              <v-text-field
                v-model="editingQuestion.key"
                variant="outlined"
                density="compact"
                label="Key"
                hint="Kobling til forretningslogik"
                class="monospace"
                :rules="[keyValidation]"
              />
            </DimsDialog>

            <!-- display hint -->
            <DimsDialog title="Specialvisning">
              <template #activator="{ props }">
                <span class="text-right text-caption">
                  Visning: <code>{{ editingQuestion.display || "Normal" }}</code>
                  <v-btn icon="mdi-key-link" v-bind="props"></v-btn>
                </span>
              </template>
              <p>
                Alternativ visning af spørgsmål
              </p>
              <v-select
                clearable
                :items="displayOptions"
                v-model="editingQuestion.display"
              />
            </DimsDialog>
            Variant: {{ editingQuestion?.variant ?? '-' }}
          </div>
          <v-expansion-panels v-model="panels">
            <v-expansion-panel
              value="access"
              style="border: solid 1px; border-color: rgb(0 0 0 / 0.38);"
            >
              <v-expansion-panel-title>
                <b>Adgang</b>&nbsp;<span v-show="isAccessPanelCollapsed">{{ accessSummary }}</span>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-checkbox
                  v-model="editingQuestion.accessibleForCustomer"
                  :disabled="!isQuestionAccessEditable(editingQuestion)"
                  label="Kundeadgang"
                  density="compact"
                  class="mb-n3 mr-3"
                ></v-checkbox>
                <div>Leverandøradgang</div>
                <v-radio-group
                  v-model="selectedSupplierAccess"
                  density="compact"
                  :disabled="!isQuestionAccessEditable(editingQuestion)"
                  @update:modelValue="onSelectedSupplierAccessUpdate"
                >
                  <v-radio :label="'Ingen'" :value="'None'" />
                  <v-radio :label="'Read-only'" :value="'ReadOnly'" />
                  <v-radio :label="'Read/write'" :value="'ReadWrite'" />
                </v-radio-group>
                <v-btn
                  @click="setVisibilityOnDescendantQuestions()"
                  :disabled="!editing || !isQuestionAccessEditable(editingQuestion)"
                  class="primary-button"
                  density="compact"
                >
                  Sæt på underliggende spørgsmål
                </v-btn>
                &nbsp;
                <span>(Bemærk: Alle ændringer, også af underliggende spørgsmål, gemmes først ved 'Gem').</span>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-text-field
            v-model="editingQuestion.title"
            variant="outlined"
            density="compact"
            label="Titel"
            class="mt-5"
            data-cy="maintitle"
          />
          <v-row v-if="isLevel1Question && enableTabConfiguration">
            <v-col>
              <v-text-field
                v-model="tabConfigurationTitleCustomer"
                variant="outlined"
                density="compact"
                label="Kundefane titel"
                :hide-details="true"
              />
            </v-col>
            <v-col :cols="3">
              <div v-for="item in tabConfigurationAvailableTypes" :key="item.value">
                <label>
                  <input
                    type="checkbox"
                    :value="item.value"
                    v-model="tabConfigurationTypes"
                  />
                  {{ item.label }}
                </label>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isLevel1Question && enableTabConfiguration" class="mt-0 mb-3">
            <v-col>
              <v-text-field
                v-model="tabConfigurationTitleSupplier"
                variant="outlined"
                density="compact"
                label="Leverandørfane titel"
                :hide-details="true"
              />
            </v-col>
            <v-col :cols="3" />
          </v-row>
          <v-textarea
            v-if="!root"
            v-model="editingQuestion.helpText"
            variant="outlined"
            density="compact"
            label="Info tekst (tooltip)"
            :hide-details="true"
          />
          <RichTextEditor
            v-if="editingQuestion.questionType === 'Text'"
            v-model="editingQuestion.defaultValue"
            ref="richTextEditor"
            :admin="true"
            label="Default værdi"
          />
          <RichTextEditor
            v-model="text"
            ref="richTextEditor"
            class="mb-5"
            :admin="true"
            label="Hjælpetekst"
          />
          <RichTextEditor
            v-model="supplierText"
            ref="richTextEditor"
            class="mb-5"
            :admin="true"
            label="Hjælpetekst (leverandør)"
          />
          <VariantForm
            :value="(editingQuestion as SectionQuestionDefinition)"
            :question="(question as SectionQuestionDefinition | null)"
          />
          <MandatoryAnswerSelector
            v-if="isBooleanType"
            :value="mandatoryAnswerValue ?? undefined"
            @changeToRadioButtons="onChangeToRadioButtons"
          />
          <v-row v-if="isFileUploadType || isNumericType || isTextType || isSimpleTextType">
            <v-col>
              <OptionalFieldSelector
                :value="optionalFieldSelectorValue"
                @optionalFieldSelectorChanged="onOptionalFieldSelectorChanged"
              />
            </v-col>
            <v-col v-if="isTextType || isNumericType">
              <LockedFieldSelector
                :value="lockedFieldSelectorValue"
                @lockedFieldSelectorChanged="onLockedFieldSelectorChanged"
              />
            </v-col>
          </v-row>
          <DependenciesDisplay
            v-if="!root"
            :locator
            :condition="editingQuestion.dependsOn"
            :currentQuestion="editingQuestion"
            @conditionChange="onConditionChange"
          />
          <OptionsEditor
            v-if="editingQuestion.questionType === 'Options'"
            :question="editingQuestion"
            @optionsChange="onOptionsChange"
          />
          <CheckboxList
            v-if="
              editingQuestion.questionType === 'Section'
                && editingQuestion.variant === 'checkbox-list'
            "
            :question="editingQuestion"
          />
          <FileSet
            v-if="editingQuestion.questionType === 'FileDownload'"
            :editing
            :template
            :question="editingQuestion"
            @attachmentsUpdated="attachmentsUpdated"
          />
          <PricePointSet
            v-if="editingQuestion.questionType === 'PricePointSet'"
            :question="editingQuestion"
            :questions
            :template
          />
          <NumericInputEditor
            v-if="editingQuestion.questionType === 'Number'"
            :question="editingQuestion"
            :template="template"
          />
          <IntervalsEditor
            v-if="editingQuestion.questionType === 'Intervals'"
            :question="editingQuestion"
            :template="template"
          />
          <v-select
            v-if="editingQuestion.questionType === 'Placeholder'"
            v-model="placeholderVariantType"
            :items="placeholderVariants"
            variant="outlined"
            label="Placeholder type"
          ></v-select>
        </template>
      </v-card-text>
      <v-card-actions v-if="editingQuestion && !selectQuestionType">
        <v-spacer />
        <v-btn class="secondary-button" @click="model = false">
          Annuller
        </v-btn>
        <v-btn
          data-cy="savebtn"
          class="primary-button"
          @click="saveTemplate()"
          :disabled="selectQuestionType || editingQuestion.title === '' || showAlert"
        >
          Gem
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  QuestionnaireTemplate,
  OptionDefinition,
  QuestionDefinition,
  RichTextEditor,
  Condition,
  Attachment,
  PricePointSetDefinition,
  IntervalsDefinition,
  PlaceholderVariantType,
  placeholderVariantTypeList,
  SectionQuestionDefinition,
  SupplierAccess,
  useFeatures,
} from '@dims/components';
import { ref, computed, watch } from 'vue';
import { v4 as newUUID } from 'uuid';
import _ from 'lodash';
import FileSet from '@/components/shared/FileSet.vue';
import { applyVariant } from '@/models/Variants';
import {
  isIntervals,
  isOption,
  isPricePointSet,
  isSection,
  isSKIFiles,
  isNumeric,
  isText,
} from '@/components/Templates/utilities';
import { questionCardCategories, QuestionTypeCard } from '@/models';
import OptionsEditor from './QuestionForm/OptionsEditor.vue';
import QuestionTypeSelector from './QuestionForm/QuestionType/QuestionTypeSelector.vue';
import VariantForm from './QuestionForm/VariantForm.vue';
import MandatoryAnswerSelector from './QuestionForm/MandatoryAnswerSelector.vue';
import OptionalFieldSelector from './QuestionForm/OptionalFieldSelector.vue';
import LockedFieldSelector from './QuestionForm/LockedFieldSelector.vue';
import DependenciesDisplay from './QuestionForm/DependenciesDisplay.vue';
import PricePointSet from './QuestionForm/PricePointSet/PricePointSet.vue';
import IntervalsEditor from './QuestionForm/IntervalsEditor.vue';
import QuestionLocator from './QuestionLocator';
import NumericInputEditor from './QuestionForm/NumericInputEditor.vue';
import CheckboxList from './CheckboxList.vue';
import ExpandedStateKeeper from './ExpandedStateKeeper';
import DimsDialog from './DimsDialog.vue';
import TemplateAlert from './TemplateAlert.vue';
import { isQuestionAccessEditable, defaultQuestionAccess } from '@/utils';
import ValidationRules from './ValidationRules.vue';
import keyValidation from './QuestionForm/keyValidation';

interface RichTextEditorElement {
  setContent(): void;
}

/** The popup dialog adding/editing a question */
// TODO: Rename eg. to EditQuestionDialog
const emit = defineEmits<{ fetchTemplate: [], updateTemplate: [QuestionnaireTemplate] }>();
const { template, question = null, root = false, locator, expandedStateKeeper, editing = false } = defineProps<{
  template: QuestionnaireTemplate;
  /**
   * If edit: The question being edited.
   * If add: The PARENT to the question being added
   *   null if a new top-level question is being added
   */
  question?: QuestionDefinition | null;
  root?: boolean;
  locator: QuestionLocator;
  expandedStateKeeper: ExpandedStateKeeper;
  /** Indicate if we are editing an existing question or adding a new */
  editing?: boolean;
}>();

const features = useFeatures();

const showAlert = ref(false);
const alertText = ref('');

const model = defineModel<boolean>();
/** Show the question-type selection dialog */
const selectQuestionType = ref(false);
/** A deep clone of the question under edit */
const editingQuestion = ref<QuestionDefinition | null>(null);
const selectedSupplierAccess = ref<SupplierAccess>();

/** Currently we just hardcode which agreements supports top-level repeaters. This should either be removed (if all should support it)
 * or moved to AgreementConfiguration (or another configuration) */
const enableRootLevelRepeater = computed(() => template.agreementName === '02140024' || template.agreementName === '50860025');

const enableTabConfiguration = computed(() => template.agreementName === '17130024' || template.agreementName == '02170025');

/** Handles subquestion when changing ShowOnSupplierView */
const changeSubQuestions = ref(false);

const panels = ref<string>();

const isAccessPanelCollapsed = computed(() => panels.value === undefined);

const placeholderVariantType = computed({
  get() {
    return editingQuestion.value?.variant as PlaceholderVariantType;
  },
  set(value: PlaceholderVariantType) {
    if (editingQuestion.value) {
      editingQuestion.value.variant = value;
    }
  },
});

const questions = computed(() => template.questions);

const text = computed({
  get() {
    return editingQuestion.value?.text ?? '';
  },
  set(value: string) {
    if (editingQuestion.value) {
      editingQuestion.value.text = value;
    }
  },
});

const supplierText = computed({
  get() {
    return editingQuestion.value?.supplierText ?? '';
  },
  set(value: string) {
    if (editingQuestion.value) {
      editingQuestion.value.supplierText = value;
    }
  },
});

const accessSummary = computed(() => {
  const customer = () => {
    switch (editingQuestion.value?.accessibleForCustomer) {
      case true: {
        return 'ja';
      }
      case false: {
        return 'nej';
      }
      default: {
        return '-';
      }
    }
  };
  const supplier = () => {
    switch (editingQuestion.value?.accessForSupplier) {
      case 'ReadOnly': {
        return 'read-only';
      }
      case 'ReadWrite': {
        return 'read/write';
      }
      case 'None': {
        return 'nej';
      }
      default: {
        return '-';
      }
    }
  };
  return ` (Kunde: ${customer()}, Leverandør: ${supplier()})`;
});

const tabConfigurationTitleCustomer = computed({
  get() {
    return editingQuestion.value?.tabConfiguration?.customerTitle ?? '';
  },
  set(val: string) {
    if (editingQuestion.value) {
      if (editingQuestion.value.tabConfiguration) {
        editingQuestion.value.tabConfiguration.customerTitle = val;
      } else {
        editingQuestion.value.tabConfiguration = ({ customerTitle: val, supplierTitle: '', types: [], sectionIndex: -1 });
      }
    }
  }
});

const tabConfigurationTitleSupplier = computed({
  get() {
    return editingQuestion.value?.tabConfiguration?.supplierTitle ?? '';
  },
  set(val: string) {
    if (editingQuestion.value) {
      if (editingQuestion.value.tabConfiguration) {
        editingQuestion.value.tabConfiguration.supplierTitle = val;
      } else {
        editingQuestion.value.tabConfiguration = ({ customerTitle: '', supplierTitle: val, types: [], sectionIndex: -1 });
      }
    }
  }
});

const tabConfigurationAvailableTypes = computed(() => {
  return [
    { label: 'Kravspecifikation', value: 'CustomerSpecification' },
    { label: 'Tilbud', value: 'SupplierOffer' },
  ];
});

const tabConfigurationTypes = computed({
  get() {
    return editingQuestion.value?.tabConfiguration?.types ?? [];
  },
  set(val: string[]) {
    if (editingQuestion.value) {
      if (editingQuestion.value.tabConfiguration) {
        editingQuestion.value.tabConfiguration.types = val;
      } else {
        editingQuestion.value.tabConfiguration = ({ customerTitle: '', supplierTitle: '', types: val, sectionIndex: -1 });
      }
    }
  }
});

const questionTypeCards = questionCardCategories(features.FlexRequirements).flatMap((c) => c.cards);

const questionTypeCard = computed(() => (editingQuestion.value
  && isSection(editingQuestion.value)
  && editingQuestion.value.variant
  ? questionTypeCards.find(
    (c) => c.variant === editingQuestion.value?.variant,
  )
  : questionTypeCards.find(
    (c) => c.type === editingQuestion.value?.questionType,
  )));

const isNumericType = computed(
  () => editingQuestion.value?.questionType === 'Number',
);

const isTextType = computed(
  () => editingQuestion.value?.questionType === 'Text',
);

const isSimpleTextType = computed(
  () => editingQuestion.value?.questionType === 'SimpleText',
);

const isBooleanType = computed(
  () => editingQuestion.value
    && (editingQuestion.value.questionType === 'Boolean'
      || editingQuestion.value.questionType === 'BooleanOptions'),
);
const mandatoryAnswerValue = computed(
  () => editingQuestion.value
    && editingQuestion.value.questionType === 'BooleanOptions',
);

const isFileUploadType = computed(
  () => editingQuestion.value?.questionType === 'Files'
    || editingQuestion.value?.variant === 'supplier-file-upload',
);

const optionalFieldSelectorValue = computed(() => !editingQuestion.value?.required);

const lockedFieldSelectorValue = computed(() => {
  return (
    (editingQuestion.value?.questionType === "Text" ||
      editingQuestion.value?.questionType === "Number") &&
        (editingQuestion.value.locked ?? false)
  );
});

const isLevel1Question = computed(() =>
  questions.value.some((q) => q.questionDefinitionId === editingQuestion.value?.questionDefinitionId)
);

watch(model, () => {
  showAlert.value = false;
  alertText.value = '';

  panels.value = undefined;
  if (model.value) {
    if (editing) {
      editingQuestion.value = _.cloneDeep(question);
      selectedSupplierAccess.value = question?.accessForSupplier;
      resetRichTextEditor();
      selectQuestionType.value = false;
    } else {
      editingQuestion.value = null;
      selectQuestionType.value = true;
    }
  }
});

function setVisibilityOnDescendantQuestions() {
  if (editingQuestion.value && editingQuestion.value.accessibleForCustomer && editingQuestion.value.accessForSupplier) {
    const { accessibleForCustomer, accessForSupplier } = editingQuestion.value;
    setVisibilityOnSubQuestionsRecursive(editingQuestion.value, accessibleForCustomer, accessForSupplier);
  }
}

function setVisibilityOnSubQuestionsRecursive(question2: QuestionDefinition, accessibleForCustomer: boolean, accessForSupplier: SupplierAccess) {
  question2.questions?.forEach((q) => {
    if (isQuestionAccessEditable(q)) {
      const subQuestion = q;
      subQuestion.accessibleForCustomer = accessibleForCustomer;
      subQuestion.accessForSupplier = accessForSupplier;
    }
    setVisibilityOnSubQuestionsRecursive(q, accessibleForCustomer, accessForSupplier);
  });
}

function initializeQuestionValues(card: QuestionTypeCard, defaultAccess: { accessibleForCustomer: boolean; accessForSupplier: SupplierAccess; }) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  editingQuestion.value = {
    name: '',
    questionDefinitionId: '',
    questionType: card.type,
    title: '',
    text: '',
    helpText: '',
    required: true,
    dependsOn: undefined,
    options: [],
    questions: [],
    defaultValue: undefined,
    variant: card.variant,
    accessibleForCustomer: defaultAccess.accessibleForCustomer,
    accessForSupplier: defaultAccess.accessForSupplier,
  } as QuestionDefinition;

  if (card.variant) {
    editingQuestion.value.questions = [];
    applyVariant(editingQuestion.value, card.variant, template.agreementName);
  }
}

function convertQuestionType(card: QuestionTypeCard) {
  if (editingQuestion.value) {
    if (editingQuestion.value.variant) {
      editingQuestion.value.questions = [];
    }

    editingQuestion.value.questionType = card.type;
    editingQuestion.value.variant = card.variant;

    if (card.variant) {
      editingQuestion.value.questions = [];
      applyVariant(editingQuestion.value, card.variant, template.agreementName);
    }
  }
}

function questionTypeSelected(card: QuestionTypeCard) {
  showAlert.value = false;
  alertText.value = '';

  if (card.variant && editingQuestion.value && hasBlockingSubQuestions(editingQuestion.value)) {
    showAlert.value = true;
    alertText.value = 'Spørgsmål med underliggende spørgsmål kan ikke skifte til denne type. Slet underliggende spørgsmål for at skifte type.';
  } else {
    updateQuestion(card, true);
  }
}

function hasBlockingSubQuestions(questionToCheck: QuestionDefinition) {
  if (questionToCheck.questions?.length && questionToCheck.questions.length > 0) {
    if (questionToCheck.questions.length === 1 && questionToCheck.questions[0]?.variant === 'include-requirement') {
      return false;
    }

    return true;
  }

  return false;
}

function updateQuestion(card: QuestionTypeCard, allowQuestionTypeConversion: boolean) {
  selectQuestionType.value = false;
  const defaultAccess = defaultQuestionAccess(card.type, card.variant);

  if (allowQuestionTypeConversion && editingQuestion.value) {
    convertQuestionType(card);
  } else {
    initializeQuestionValues(card, defaultAccess);
  }

  selectedSupplierAccess.value = defaultAccess.accessForSupplier;

  applyPricePointSet();
  applyIntervals();
}

function saveTemplate() {
  if (question && editingQuestion.value && editing) {
    /* Copy properties from the edited clone to the original question */
    question.title = editingQuestion.value.title;
    question.text = editingQuestion.value.text;
    question.supplierText = editingQuestion.value.supplierText;
    question.questionType = editingQuestion.value.questionType;
    const { dependsOn, helpText } = editingQuestion.value;
    question.helpText = helpText && helpText !== '' ? helpText : undefined;
    question.dependsOn = dependsOn && dependsOn.operands.length > 0 ? dependsOn : undefined;
    question.variant = editingQuestion.value.variant;
    question.variantData = editingQuestion.value.variantData;
    question.validation = editingQuestion.value.validation;
    // Prevent saving empty string key, which would only be possible once because of duplicate keys.
    question.key = (editingQuestion.value.key === '') ? undefined : editingQuestion.value.key;
    question.display = editingQuestion.value.display;
    question.accessibleForCustomer = editingQuestion.value.accessibleForCustomer;
    question.accessForSupplier = editingQuestion.value.accessForSupplier;
    question.questions = editingQuestion.value.questions;

    if (isNumeric(question) && isNumeric(editingQuestion.value)) {
      question.decimals = editingQuestion.value.decimals;
      question.minValue = editingQuestion.value.minValue;
      question.maxValue = editingQuestion.value.maxValue;
      question.defaultValue = editingQuestion.value.defaultValue;
      question.locked = editingQuestion.value.locked;
    }

    question.crossValidations = editingQuestion.value.crossValidations;

    question.tabConfiguration = editingQuestion.value.tabConfiguration;

    if (isText(question) && isText(editingQuestion.value)) {
      question.defaultValue = editingQuestion.value.defaultValue;
      question.locked = editingQuestion.value.locked;
    }

    if (isPricePointSet(question) && isPricePointSet(editingQuestion.value)) {
      question.pricePointSet = editingQuestion.value.pricePointSet;
    }

    if (isIntervals(question) && isIntervals(editingQuestion.value)) {
      question.intervals = editingQuestion.value.intervals;
    }

    // default to required, currently no support for optional in UI
    question.required = true;
    if (isOption(question) && isOption(editingQuestion.value)) {
      question.options = editingQuestion.value.options;
      question.defaultValue = editingQuestion.value.defaultValue;
    }

    // For variants, we also update the sub-questions
    // since they may have been modified
    // (For regular questions we don't overwite sub-questions)
    if (editingQuestion.value.variant) {
      question.questions = editingQuestion.value.questions;
    }
    // Default to required, only file uploads can be optional
    question.required = editingQuestion.value.required ?? true;

    // Update the property 'isIncludedInPublishedMaterial'.
    if (isSKIFiles(question) && isSKIFiles(editingQuestion.value)) {
      if (editingQuestion.value.attachments) {
        question.attachments = editingQuestion.value.attachments;
      }
    }
  } else if (editingQuestion.value && !editing) {
    /* Add question */
    const uuid = newUUID();
    editingQuestion.value.questionDefinitionId = uuid;
    editingQuestion.value.name = uuid;

    // Default to required, only file uploads can be optional
    editingQuestion.value.required = editingQuestion.value.required ?? true;

    // Add new question to root node or as nested node
    if (question) {
      // Adding the new question to its parent
      question.questions = [
        ...(question.questions ?? []),
        editingQuestion.value,
      ];
      expandedStateKeeper.setIsExpanded(
        question.questionDefinitionId,
        true,
      );
    } else {
      // Adding the new question at root
      questions.value.push(editingQuestion.value);
    }
    expandedStateKeeper.setIsExpanded(
      editingQuestion.value.questionDefinitionId,
      false,
    );
  }
  emit('updateTemplate', template);
  changeSubQuestions.value = false;
  model.value = false;
}

const dialogTitle = computed(() => {
  if (editing) {
    return `Rediger <b>${question?.title}</b>`;
  }
  return question?.questionDefinitionId
    ? `Tilføj nyt niveau til <b>${question.title}</b>`
    : 'Tilføj nyt niveau 1';
});

function onConditionChange(condition: Condition | undefined) {
  if (editingQuestion.value) {
    editingQuestion.value.dependsOn = condition;
  }
}

// When a boolean has its mandatory flag changed
// Changes questiontype between Boolean and BooleanOptions
function onChangeToRadioButtons(value: boolean | null) {
  if (editingQuestion.value) {
    editingQuestion.value.questionType = value ? 'BooleanOptions' : 'Boolean';
  }
}

function onOptionalFieldSelectorChanged(value: boolean | null) {
  if (editingQuestion.value) {
    console.log('set required: ', !value);
    editingQuestion.value.required = !value; // required is the opposite of optional
  }
}

function onLockedFieldSelectorChanged(value: boolean | null) {
  if (editingQuestion.value && (editingQuestion.value.questionType === 'Text' ||
    editingQuestion.value.questionType === "Number")) {
    console.log('set locked: ', value);
    editingQuestion.value.locked = value ?? undefined;
  }
}

function onOptionsChange(options: OptionDefinition[]) {
  if (editingQuestion.value && isOption(editingQuestion.value)) {
    editingQuestion.value.options = options;
  }
}

function attachmentsUpdated(attachments: Attachment[]) {
  if (editingQuestion.value && isSKIFiles(editingQuestion.value)) {
    editingQuestion.value.attachments = attachments;
    emit('fetchTemplate');
  }
}

const richTextEditor = ref<RichTextEditorElement>();

function resetRichTextEditor() {
  if (richTextEditor.value) {
    richTextEditor.value.setContent();
  }
}

function applyPricePointSet() {
  if (editingQuestion.value && isPricePointSet(editingQuestion.value)) {
    editingQuestion.value.pricePointSet = {
      configurationId: '',
      intervalsQuestionId: '',
      configurationGroupsIds: [],
    } satisfies PricePointSetDefinition;
  }
}

function applyIntervals() {
  if (editingQuestion.value && isIntervals(editingQuestion.value)) {
    editingQuestion.value.intervals = {
      unit: 'År',
      defaultValue: 8,
      minValue: 0,
      maxValue: 8,
    } satisfies IntervalsDefinition;
  }
}

const placeholderVariants = computed((): PlaceholderVariantType[] => placeholderVariantTypeList.map((element) => element));

function onSelectedSupplierAccessUpdate() {
  if (editingQuestion.value && selectedSupplierAccess.value !== undefined) {
    editingQuestion.value.accessForSupplier = selectedSupplierAccess.value;
  }
}

const displayOptions = [
  { title: 'Tabelvisning', value: 'Table', subtitle: 'Vis repeater som tabel' },
  { title: 'Kolonne', value: 'TableColumn', subtitle: 'Inkluder spørgsmål i tabelvisning' },
];

</script>
